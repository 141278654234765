@import url(../../../CSS/root.css);

/* .section-two-wrapper {
   background-image: url('../../../Assets/Svg/application-wave-inv.svg');
  background-repeat: no-repeat;
  background-size: 100%; 
} */

.section-two-container {
  padding: 5em 0;
  width: 80%;
  margin: 0 auto;
}

.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 30px auto;
  word-wrap: break-word;
}

.card-container {
  margin: 5px;
  overflow: hidden;
  box-shadow: 0px 0px 15px -5px;
  min-height: 250px;
  background-image: url('../../../Assets/Svg/card-background.svg');
}

.card-content {
  margin: 1rem;
  margin-top: 0.3rem;
}

.card-readmore {
  margin: 0;
  padding: 0;
  color: white;
  font-size: 18px;
}

/* @media (max-width:1100px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
    margin: 1em 0;
  }
} */

@media (max-width:1100px) {
  .cards {
    grid-template-columns: repeat(1, 1fr);
    margin: 1em 0;
  }
}


.box {
  position: relative;
  padding: 16px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #333;
  padding: 2em;
  opacity: 80%;
  box-shadow: 0rem 0rem 0.1rem 0.1rem rgba(0, 0, 0, 0.1),
    0rem 0rem 0.2rem 0.2rem rgba(0, 0, 0, 0.1),
    0rem 0rem 0.3rem 0.4rem rgba(0, 0, 0, 0.1);
}

.box .first-layer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.box span {
  display: block;
  position: absolute;
  background-color: var(--fifth-color);
}

.box h2 {
  text-transform: uppercase;
}

.box h2,
.box h4,
.box .box-description {
  color: white;
}

.box .box-description-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.25s;
  backdrop-filter: blur(10px);
}

.box .first-layer:hover {
  opacity: 0;
}

.box .box-description-container:hover {
  opacity: 1;
}

.box .box-description {
  font-size: 2.5em;
  margin-top: 0.25em;
  transform: translateY(50px);
  transition: transform 0.25s;
}

.box .box-description-container:hover>* {
  transform: translate(0);
}

.box span:nth-child(1) {
  left: 0;
  bottom: 0;
  width: 2px;
  height: 100%;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.5s;
}

.box span:nth-child(2) {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.5s;
}

.box span:nth-child(3) {
  right: 0;
  bottom: 0;
  width: 2px;
  height: 100%;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 0.5s;
}

.box span:nth-child(4) {
  left: 0;
  top: 0;
  width: 100%;
  height: 2px;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s;
}

.box:hover {
  opacity: 100%;
  cursor: pointer;
}

.box:hover span:nth-child(1) {
  transform: scaleY(1);
  transform-origin: bottom;
  transition: transform 0.5s;
}

.box:hover span:nth-child(2) {
  transform: scaleX(1);
  transform-origin: left;
  transition: transform 0.5s;
}

.box:hover span:nth-child(3) {
  transform: scaleY(1);
  transform-origin: top;
  transition: transform 0.5s;
}

.box:hover span:nth-child(4) {
  transform: scaleX(1);
  transform-origin: right;
  transition: transform 0.5s;
}

/* for the blur part when you hover */

.box-description-container span:nth-child(1) {
  left: 0;
  bottom: 0;
  width: 2px;
  height: 100%;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.5s;
}

.box-description-container span:nth-child(2) {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.5s;
}

.box-description-container span:nth-child(3) {
  right: 0;
  bottom: 0;
  width: 2px;
  height: 100%;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform 0.5s;
}

.box-description-container span:nth-child(4) {
  left: 0;
  top: 0;
  width: 100%;
  height: 2px;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.5s;
}

.box-description-container:hover {
  opacity: 100%;
}

.box-description-container:hover span:nth-child(1) {
  transform: scaleY(1);
  transform-origin: bottom;
  transition: transform 0.5s;
}

.box-description-container:hover span:nth-child(2) {
  transform: scaleX(1);
  transform-origin: left;
  transition: transform 0.5s;
}

.box-description-container:hover span:nth-child(3) {
  transform: scaleY(1);
  transform-origin: top;
  transition: transform 0.5s;
}

.box-description-container:hover span:nth-child(4) {
  transform: scaleX(1);
  transform-origin: right;
  transition: transform 0.5s;
}

.section-two-icon {
  font-size: 70px;
  color: var(--fifth-color);
}


.box-read-more {
  /* text-align: center; */
  padding: 0.5em;
  color: white;
}


@media(max-width:1100px) {
  .box h2 {
    font-size: 18px;
  }

  .card-container {
    min-height: 150px;
  }
}

@media(max-width:900px) {
  .box h2 {
    font-size: 15px;
  }

  .box h4 {
    font-size: 12px;
  }
}