@import url('../../../../CSS/root.css');

.main-footer .footer-container {
  background-color: var(--eighth-color);
  width: 100%;
}

.main-footer .signature {
  background-color: var(--ninth-color);
  padding: 0.6em 0;
}

.main-footer .signature p {
  color: rgba(197, 197, 197, 0.7);
  text-align: center;
  font-size: 14px;
}

.main-footer .footer-container .row {
  position: relative;
  padding: 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.footer-col {
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-col h3 {
  display: inline-block;
  color: #f5f5f5;
  font-size: 25px;
  text-transform: capitalize;
  margin-bottom: 35px;
  font-weight: 500;
  position: relative;
}

.footer-col h3::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  background-color: var(--secondary-color);
  height: 2px;
  box-sizing: border-box;
  width: 100%;
}

.footer-col ul {
  list-style: none;
  text-align: center;
}

.footer-col ul li:not(:last-child) {
  margin-bottom: 10px;
}

.footer-col ul li {
  font-size: 20px;
  text-transform: capitalize;
  color: #8e8e7f;
  text-decoration: none;
  font-weight: 300px;
  display: block;
  transition: all 0.3s ease;
}

.footer-col ul li:hover {
  color: var(--secondary-color);
  padding-left: 10px;
}

.social-links {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}



.social-links .footer-icon {
  font-size: 30px;
  line-height: 60px;
  transition: 0.6s;
  color: #8e8e7f;
  margin: 0 20px 0 0;
  transition: 250ms;
  border-radius: 5px;
  box-shadow: 0 0px 4px #8e8e7f;
}

.social-links .footer-icon:hover {
  color: var(--fourth-color);
  box-shadow: 0 0 0;
}

@media(max-width:1500px) {
  /* .main-footer .footer-container .row {
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    align-items: center;
  } */

  .footer-col {
    display: inline-flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
  }
}

@media(max-width:1200px) {
  /* .social-links {
    grid-template-columns: repeat(2, 1fr);
  } */

  .main-footer .footer-container .row {
    grid-template-columns: repeat(2, auto);
  }
}

@media(max-width:767px) {
  .main-footer .footer-container .row {
    grid-template-columns: repeat(1, auto);
    text-align: center;
  }

  .footer-col h3::before {
    display: none;
  }

  .footer-col h3 {
    font-size: 25px;
  }

  .footer-col ul li {
    font-size: 16px;
  }

  .footer-col h3 {
    text-decoration: underline var(--secondary-color);
    text-underline-offset: 3px;
  }

  .social-links {
    width: 50%;
    margin: 0 auto;
    justify-content: center;
    grid-template-columns: repeat(2, 1fr);
  }
}