.Application-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Take-me-back-button {
  /* border: 2px solid var(--fifth-color); */
  margin-top: 30px;
  padding: 10px;
  transition: all .35s;
  border-radius: 5px;
}

.Take-me-back-button:hover {
  background-color: var(--fifth-color);
}

.Whitelist-application-wrapper {
  width: 80%;
  margin: 0 auto;
}

.Applications-container {
  display: flex;
  flex-direction: column;
  color: white;
  padding: 1em 0;
}

.Application-separation-line {
  grid-column: 1 /-1;
  height: 2px;
  background-color: var(--fourth-color)
}

.Applications-container-item {
  margin: 30px 0;
}

.Applications-container-item:first-child {
  margin: 0;
}

.Label-group {
  display: flex;
  flex-direction: column;
}

.Label-group label {
  font-size: 20px;
  font-weight: 500;
}

.Input-example {
  margin: 5px 0;
  color: var(--fifth-color);
  font-size: 18px;
}

.Input-minimum-length {
  color: var(--fifth-color);
  font-size: 16px;
  margin: 10px 0;
}

.Input-minimum-length-done {
  color: lime;
  font-size: 16px;
  margin: 10px 0;
}

.Radio-grid,
.Radio-smaller-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
}

.Radio-values {
  margin: 20px;
  border: 2px solid var(--fourth-color);
  border-radius: 5px;
}

.Radio-values:hover {
  background-color: var(--fourth-color);
  transition: 150ms ease-in-out;
}

.Radio-label {
  font-size: 18px;
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Applications-container input[type="text"],
.Applications-container textarea {
  width: 100%;
  padding: 1em;
  border: none;
  outline: none;
  margin: 10px 0;
  border-radius: 5px;
  resize: vertical;
  font-size: 16px;
}


.Applications-container label {
  cursor: pointer;
}

.Applications-container .Applications-secondary-title {
  display: inline-block;
  margin: 10px 0;
  text-transform: uppercase;
  border-bottom: 3px solid var(--fifth-color);
  font-size: 32px;
}

/* More styling for the before can be found in the sections.css file*/
.Whitelist-title::before {
  content: 'Apply for whitelist'
}

.Personal-questions,
.Character-questions {
  padding: 2em 0;
}

.Applications-container .contact-send-button {
  width: 50%;
  margin: 0 auto;
}


.glow {
  height: 50vh;
  font-size: 80px;
  color: #fff;
  text-align: center;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073;
  }

  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
  }
}

.Success-image {
  width: 15%;
  animation: spinning 4s ease-in-out infinite;
}

@keyframes spinning {
  25% {
    transform: rotate(45deg) scale(0.8);
  }

  100% {
    transform: rotate(-360deg) scale(1.0);
  }
}


@media(max-width:767px) {
  .Radio-grid {
    grid-template-columns: auto;
  }

  .Applications-container .Applications-secondary-title {
    font-size: 25px;
  }

  .Radio-label {
    font-size: 14px;
  }

  .Label-group label {
    font-size: 14px;
  }

  .Applications-container input[type="text"],
  .Applications-container textarea {
    font-size: 14px;
  }
}