.contact-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin: 0;
  width: 50%;
  margin: 5em auto 5em auto;
}

.contact-section input,
.contact-section textarea {
  background-color: white;
  border-radius: 5px;
  border: none;
  padding: 1em;
  margin: 5px 0;
}

.contact-section textarea:focus,
.contact-section input:focus {
  outline: none;
}

.contact-success-message {
  color: white;
  display: flex;
}


.contact-section {
  grid-column: 1/-1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-name,
.contact-email,
.contact-message {
  box-shadow:
    0 0 8px black;
}

.wrapper {
  align-self: center;
  top: 50%;
  left: 50%;
  margin-top: 20px;
}

.contact-send-button {
  display: block;
  width: 200px;
  line-height: 40px;
  font-size: 18px;
  font-family: sans-serif;
  text-decoration: none;
  color: var(--fourth-color);
  border: 2px solid var(--fourth-color);
  letter-spacing: 2px;
  text-align: center;
  position: relative;
  transition: all .35s;
  border-radius: 5px;
}

.contact-send-button span {
  position: relative;
  z-index: 2;
}

.contact-send-button:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--fourth-color);
  transition: all .35s;
}

.contact-send-button:hover {
  color: #fff;
  cursor: pointer;
}

.contact-send-button:hover:after {
  width: 100%;
}

.social-media {
  grid-column: 7 / -1;
  display: flex;
}

.story-verse-logo-contact-image {
  max-width: 75%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.contact-box {
  border: 2px solid var(--secondary-color);
  border-radius: 5px;
}

/* .contact-header-title {
  margin: 0 auto;
  color: white;
} */

#background-video {
  border-radius: 15px;
  object-fit: cover;
  aspect-ratio: 16/9;
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  box-shadow: 0 0 4px var(--secondary-color),
    0 0 8px var(--secondary-color),
    0 0 12px var(--secondary-color),
    0 0 16px var(--secondary-color),
    0 0 24px var(--secondary-color);
}

@media(max-width:1100px) {
  .contact-section {
    grid-column: 1 / 13;
  }

  .social-media {
    display: none;
  }

}