@import url(../../../CSS/root.css);


.section-one-container {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}

.section-one-container h1 {
  color: white;
}


.section-one-background-image {
  border-radius: 15px;
  object-fit: cover;
  aspect-ratio: 16/9;
  display: flex;
  justify-content: center;
  width: 70%;
  margin: 0 auto;
  animation: hovering infinite 5s ease-in-out;
  position: relative;
}


@keyframes hovering {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);

  }

  100% {
    transform: scale(1);

  }
}


.section-one-join-buttons {
  display: flex;
  justify-content: space-between;

}


.section-one-join-buttons .box {
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
}

.section-one-join-buttons h1 {
  background-color: transparent;
  text-transform: uppercase;
  border: none;
  color: white;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
}

@media(max-width:900px) {
  .section-one-background-image {
    aspect-ratio: 16/11;
  }


  .section-one-join-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .section-one-join-buttons a:first-child {
    display: none;
  }

}