@import url(../../../CSS/root.css);

h1, h2, h3, h4, h5, h6 {
  color: white;
}

h1 {
  color: var(--fifth-color);
}

a {
  text-decoration: underline;
  color: var(--fifth-color);
}

.highlight {
  color: var(--fifth-color);
}

.intro {
  padding: 2em;
  font-size: 20px;
}

.rules-container {
  padding-top: 2em;
  width: 80%;
  margin: 0 auto;
  z-index: 1;
}

/* 
.rules-container .rules-header-title {
  display: block;
  text-align: center;
  font-size: 50px;
  color: var(--fourth-color);
} */

.rules-filter {
  display: flex;
  margin: 0 auto;
  border: none;
  outline: none;
  border-radius: 5px;
  text-align: center;
  background-color: white;
  padding: 1em 2em 1em 2em;
  font-size: 20px;

}

.note-div {
  padding: 1em;
}

@media(max-width:900px) {

  .rules-filter {
    font-size: 15px;
  }
}