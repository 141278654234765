@import url(./root.css);

.App {
  padding-top: 6em;
  background-image: url('../Assets/Svg/whole-page-blob-2.svg');
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}


.page-wrapper {
  background: #1c1c1c;

}

.separation-line {
  width: 100%;
  margin: 0 auto;
  height: 2px;
  background-color: var(--fourth-color);
}

.scroll-to-top-div {
  display: flex;
  justify-content: center;
  transform: rotate(180deg);
  padding: 0.2em;
  margin: 1em;
}

.scroll-to-top-icon {
  position: relative;
  font-size: 50px;
  cursor: pointer;
  color: white;
  animation: scale-animation infinite 5s ease-in-out;
  border-radius: 50%;
  background-color: #222;
}

.loading-screen {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-screen-spinner {
  margin: 0 auto;
  width: 15%;
  animation: logo-spin 1s ease-in-out;
}

@keyframes logo-spin {
  25% {
    transform: rotate(45deg) scale(0.8);
  }

  100% {
    transform: rotate(-360deg) scale(1.0);
  }
}

@keyframes scale-animation {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }

}

.section-three-title,
.section-two-title,
.Whitelist-title,
.contact-header-title {
  text-transform: uppercase;
  margin-left: 0;
  font-size: 32px;
  background-image: linear-gradient(45deg, #fd7bf2,
      hsl(27, 93%, 57%)200px);
  background-size: 100%;
  background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

/* This is the red titles with the text before it */

.section-three-title::before,
.section-two-title::before,
.Whitelist-title::before,
.contact-header-title::before

/* ,.rules-header-title::before  */
  {
  margin: 0 auto;
  display: block;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  color: white;
}

.section-three-title::before {
  content: 'The Community';
}

.section-two-title::before {
  content: 'The Server';
}

.contact-header-title::before {
  content: 'For Business';
}

.rules-header-title::before {
  content: 'Read The Rules'
}





@media(max-width:900px) {

  .section-three-title:before,
  .section-two-title::before,
  .Whitelist-title::before {

    font-size: 12px;
  }

  .section-three-title,
  .section-two-title,
  .Whitelist-title::before {
    font-size: 18px;
  }
}