@import url(../../../CSS/root.css);

/* .section-three-background {
  background-image: url('../../../Assets/Svg/waves-finish.svg');
  background-repeat: no-repeat;
  background-size: 100%;
} */

.section-three-wrapper {
  width: 80%;
  margin: 0 auto;
}

.img-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* .section-three-sliders {
  padding: 0 20px;
} */

.section-three-container {
  width: 100%;
  margin: 30px 0;
  border-radius: 15px;
}

.swiper {
  width: 95%;
  height: 100%;
  padding: 0 50px;
}

.swiper-wrapper {
  padding: 10px 0 10px 0;
}

.swiper-slide {
  text-align: center;
  align-items: center;
  font-size: 16px;
  background: #333;
  border-radius: 15px;
  box-shadow: 0rem 0rem 0.1rem 0.05rem rgba(0, 0, 0, 0.2),
    0rem 0rem 0.2rem 0.1rem rgba(0, 0, 0, 0.2),
    0rem 0rem 0.3rem 0.15rem rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
}

.swiper-slide:hover {
  top: -5px;
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  width: 30px;
  border-radius: 0px;
  margin: 0 5px;
  height: 5px;
  background-color: var(--fifth-color);
}

.swiper-slide img {
  padding: 5px;
  margin: 20px;
  display: block;
  object-fit: cover;
  border-radius: 25px;
  box-shadow: 0rem 0rem 0.1rem 0.05rem rgba(0, 0, 0, 0.1),
    0rem 0rem 0.2rem 0.1rem rgba(0, 0, 0, 0.1),
    0rem 0rem 0.3rem 0.15rem rgba(0, 0, 0, 0.1);
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 0;
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal .swiper-pagination {
  position: static;
}

.swiper-slide h2 {
  color: white;
  bottom: 10px;
}

.section-three-wrapper .button-wrapper {
  margin: 20px 0;
}

.section-three-wrapper .button {
  padding: 1em 2em 1em 2em;
  width: 100%;
  margin: 0 auto;
  position: static;
  /* Changed from absolute to static */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: none;
  outline: none;
  font-size: 17px;
  font-weight: 900;
  color: white;
  border-radius: 30px;
  height: 50px;
  background: var(--third-color);
  transition: 250ms ease-in-out;
  box-shadow: 0rem 0rem 0.1rem 0.05rem rgba(0, 0, 0, 0.2),
    0rem 0rem 0.2rem 0.1rem rgba(0, 0, 0, 0.2),
    0rem 0rem 0.3rem 0.15rem rgba(0, 0, 0, 0.2);
}

.section-three-container .button:hover {
  cursor: pointer;
  box-shadow: 0rem 0rem 0.1rem 0.05rem var(--fifth-color),
    0rem 0rem 0.2rem 0.1rem var(--fifth-color),
    0rem 0rem 0.3rem 0.15rem var(--fifth-color);
  transition: 250ms ease-in-out;
}

.section-three-wrapper .button .stream-button {
  text-decoration: none;
  position: absolute;
  transition: 250ms ease-in-out;

}

.section-three-wrapper .button-text {
  color: white;
  text-transform: uppercase;
  transition: 250ms ease-in-out;
  text-transform: uppercase;
}

.section-three-container .button:hover .button-text {
  color: var(--fifth-color);
}

@media(max-width:767px) {
  .swiper {
    padding: 0;
  }

  .swiper-slide {
    font-size: 14px;
  }

  /* .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    display: none;
  } */

}