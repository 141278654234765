@import url(../../../../CSS/root.css);

.navbar-wrapper {
  width: 100%;
  position: fixed;
  top: 0;
  padding: 0.5em;
  transition: 250ms ease-in-out;
  z-index: 5;
}

.sticky {
  margin-top: -150px;
}

.navbar-container {
  background-color: linear-gradient(var(--primary-color), var(--seventh-color));
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 700;
}

.navbar-logo {
  width: 50px;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
}

.navbar-animation:hover {
  animation: navbar-logo-spin 5s ease-in-out;
}

.menu-icon-phone {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.toggled-class-name,
.not-toggled-class-name {
  color: var(--fifth-color);
  font-size: 30px;
  transition: all 0.5s ease;
}

.toggled-class-name {
  transform: rotate(90deg);
  transition: 250ms ease-in-out;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
}

.navbar-link {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

/*.navbar-link-shop {
  padding: 15px;
  border: solid 2px var(--fifth-color);
  border-radius: 5px;
}*/

.navbar-link:hover {
  background-image: linear-gradient(45deg, #fd7bf2,
      hsl(27, 93%, 57%) 100px);
  background-size: 100%;
  background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.navbar-link-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.btn {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn:hover {
  padding: 8px 20px;
  transition: all 0.3s ease-out;
  background-image: linear-gradient(45deg, #fd7bf2,
      hsl(27, 93%, 57%));
  background-size: 100%;
  background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  transition: 250ms;
}

.btn--primary {
  background-color: #444;
  display: flex;
  align-items: center;
}

.btn--outline {
  display: flex;
  align-items: center;
  background-color: transparent;
  color: white;
  padding: 8px 20px;
  border: 1px solid var(--fifth-color);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  border-radius: 4px;
  font-size: 18px;
  color: white;
}

.btn--large {
  padding: 12px 26px;
  border-radius: 4px;
  font-size: 20px;
  color: white;
}

@keyframes navbar-logo-spin {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(360deg);
  }

  60% {
    transform: rotate(390deg);
    transform: scale(1.7);

  }

  70% {
    transform: rotate(350deg);
  }

  80% {
    transform: rotate(390deg);
  }

  100% {
    transform: rotate(-720deg);
  }

}

@media screen and (max-width: 960px) {

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;

  }


  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 25%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    z-index: 6;
  }

  .navbar-link-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #444;
    text-decoration: none;
    color: white;
    font-size: 1.5rem;
  }

  .navbar-shopping-cart {
    background-image: linear-gradient(45deg, #fd7bf2,
        hsl(27, 93%, 57%));
  }

  .navbar-link:hover {
    background-image: linear-gradient(45deg, #fd7bf2,
        hsl(27, 93%, 57%));
    background-size: 100%;
    background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }

  .navbar-link-mobile:hover {
    background-image: linear-gradient(45deg, #fd7bf2,
        hsl(27, 93%, 57%));
    background-size: 100%;
    background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    transition: 250ms;
  }

  .navbar-container .btn {
    display: none;
  }
}