@import url(../../../CSS/root.css);

.individual-rule-container {
  padding: 1em;

}

.rules-container h2 {
  display: inline-block;
  color: white;
  font-size: 35px;
  font-weight: 400;
  color: var(--fourth-color);
  text-transform: uppercase;
}

.rules-header-section:hover h2 {
  color: var(--fifth-color);
}

.rules-container h3 {
  color: white;
  font-size: 20px;
}

/* .individual-rule-container {
  cursor: pointer;
} */

.individual-rule-container span {
  color: white;
}

.rules-header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.arrow-down-icon,
.arrow-up-icon {
  color: white;
  font-size: 25px;
  margin: 0 5px;
  font-weight: 500;
  transition: 250ms ease-in;
}

.arrow-up-icon {
  transform: rotate(180deg);
}

.rules-text {
  margin: 10px 0;
}

.rules-text span {
  color: var(--fourth-color);
}

@media(max-width:1100px) {
  .rules-container h2 {
    font-size: 30px;
  }
}

@media(max-width:900px) {
  .rules-container h2 {
    font-size: 20px;
  }
}